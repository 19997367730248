.regis_info{
    padding-top: 130px;
  text-align: center;
  margin-bottom: 100px;
}

.info_div{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-family: 'Montserrat';
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  margin: 40px 0 20px;
}

.reg_link{
  color: blue;
  text-decoration: underline;
}

.reg_link:visited{
  color: purple;
}

.info{
  font-size: 1.2rem;
  font-weight: 400;
}

.info_acc{
  font-size: 1.2rem;
  font-family: 'Montserrat';
  margin-top: 20px;
}

.table_info{
  padding: 0 40px;
}

.inf_bold{
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Montserrat';
  font-size: 1.2rem;
}

.inf_red{
  color: red;
  font-weight: 600;
}

th, td {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}
th {
  background-color: #f2f2f2;
}

@media (max-width: 550px) {
  table{
    font-size: 0.8rem;
  }
  .info_acc{
    font-size: 0.7rem;
  }
}

@media (max-width: 450px) {
  table{
    font-size: 0.6rem;
  }
}