.heart {
  color: #d946ef;
}
footer {
  overflow-x: hidden;
  /* position: absolute; */
  font-family: "Montserrat";
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  height: auto;
  padding-top: 40px;
  color: #fff;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.footer-content h3 {
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 17px;
  color: #cacdd2;
}
.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}
.socials li {
  margin: 0 10px;
  height: 100%;
}

.socials a {
  text-decoration: none;
  color: #fff;
  /* border: 1.1px solid white; */
}
.socials a i {
  font-size: 28px;
  transition: color 0.4s ease;
}

.f-menu {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.footer-bottom {
  width: 100vw;
  padding: 20px 35px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  color: #cfd2d6;
}

.footer-bottom p a {
  color: #cfd2d6;
  text-decoration: none;
}
.footer-bottom span {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 200;
}
.footer-menu {
  font-family: "Montserrat";
}
.footer-menu ul {
  display: flex;
}
.footer-menu ul li {
  display: block;
}
.footer-menu ul li a {
  color: #cfd2d6;
  text-decoration: none;
}
.footer-menu ul li a:hover {
  color: #27bcda;
}
