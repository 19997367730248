.oclist li {
  list-style-type: none;
  width: fit-content;
  font-family: "Montserrat";
  text-align: center;
}

.oclist {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  justify-items: center;
  justify-self: center;
  text-align: center;
  align-items: center;
  column-gap: 1%;
  row-gap: 20px;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 20px 0 30px;
}

.ocheading {
  font-size: 3rem;
  padding-top: 180px;
  text-align: center;
}

.org_nd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat";
}

.oc {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.org_dept {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
}

.oc ul > *:hover {
  font-weight: 600;
}

@media (max-width: 1300px) {
  .oclist {
    font-size: 1.3rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }

  .ocheading {
    font-size: 2rem;
  }
}
