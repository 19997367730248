.dates {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  font-family: "Montserrat";
  text-align: center;
  gap: 30px;
  margin-top: 40px;
}

.dates-main {
  color: white;
  background-image: url("../src/assets/comp1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.color-bg {
  background-color: rgba(2, 44, 96, 0.5);
  height: 100%;
  padding: 10px 0 40px;
  width: 100%;
}

.date-elem {
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px white;
  transition: transform 0.2s;
  background-color: rgb(2, 44, 96);
}

.date-elem:hover {
  transform: scale(1.03);
}

.date-1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.date-2 {
  font-weight: 200;
}

@media (max-width: 1100px) {
  .dates {
    flex-direction: column;
  }

  .date-elem {
    width: auto;
  }

  .date-1 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .date-2 {
    font-weight: 400;
  }
}
