.program {
  padding-top: 180px;
}

.para_in {
  font-family: "Montserrat";
  font-size: 1.4rem;
  padding: 0 30px;
  text-align: center;
  margin: 30px 0;
}

.para_in a {
  color: blue;
  text-decoration: underline;
}

.para_in a:visited {
  color: purple;
}

.program_doc_container {
    display: flex;
    justify-content: center;
    /* margin: 0 30px 40px; */
    width: 100%;
    height: 100%;
  }
  
  .program_doc {
    width: 100%;
    height: 1600px;
    max-width: 900px;
    border: none;
    /* overflow: hidden !important; Remove iframe scrollbars */
  }

  .program_doc_dett {
    width: 100%;
    height: 1600px;
    max-width: 1500px;
    border: none;
    /* overflow: hidden !important; Remove iframe scrollbars */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .program_doc {
      height: 500px;
    }
  }
  
  @media (max-width: 480px) {
    .program_doc {
      height: 400px;
    }
  }