.black-bg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  height: 800px;
  width: 100%;
  background-color: rgba(2, 44, 96, 0.45);
  padding: 2%;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../src/assets/bg-iith1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.title {
  width: 40%;
}

.title p {
  font-family: "Montserrat";
  font-size: 2.8rem;
  color: rgb(255, 255, 255);
  margin: 0 0 0px;
  font-weight: 500;
}
.title-main {
  font-family: "Montserrat";
  font-size: 5rem;
  color: rgb(255, 255, 255);
  font-weight: 700;
}

.title-dates {
  font-family: "Montserrat";
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 2.8rem;
  margin: 10px 0 10px;
}

.btn-home {
  padding: 2%;
  width: 45%;
  text-align: center;
  border: none;
  background-color: rgb(2, 44, 96, 0.7);
  font-family: "Montserrat";
  border: solid 2px rgb(2, 44, 96);
  transition: transform 0.2s;
  font-size: 1.2rem;
  color: white;
  cursor: not-allowed;
}

.btn-home:hover {
  transform: scale(1.03);
  color: yellow;
}

.btn-home-enabled {
  padding: 2%;
  width: 45%;
  text-align: center;
  border: none;
  background-color: rgb(2, 44, 96, 0.7);
  font-family: "Montserrat";
  border: solid 2px rgb(2, 44, 96);
  transition: transform 0.2s;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
}

.btn-home-disabled {
  padding: 2%;
  width: 45%;
  text-align: center;
  border: none;
  background-color: rgb(2, 44, 96, 0.7);
  font-family: "Montserrat";
  border: solid 2px rgb(2, 44, 96);
  transition: transform 0.2s;
  font-size: 1.2rem;
  color: white;
  cursor: not-allowed;
}

.btn-home-enabled-1 {
  font-family: "Montserrat";
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
}

.btn-home-enabled:hover {
  transform: scale(1.03);
  color: yellow;
}

.btn-home-enabled-1:hover {
  transform: scale(1.03);
  color: yellow;
}

.buttons-home {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 1700px) {
  .title-main {
    font-size: 4.6rem;
  }

  .title {
    width: 50%;
  }
}

@media (max-width: 1300px) {
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-main {
    font-size: 3.4em;
    text-align: center;
  }

  .title p {
    font-size: 1.7rem;
  }

  .title-dates {
    font-size: 1.7rem;
  }

  .btn-home,
  .btn-home-enabled,
  .btn-home-enabled-1 {
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 5px;
  }
}
