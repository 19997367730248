.Venue {
  margin: 50px 75px 0 75px;
  padding: 10px;
  font-family: "Montserrat";
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  text-align: center;
}

.Venue1 {
  margin: 50px 75px 0 75px;
  padding: 10px;
  font-family: "Montserrat";
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  text-align: center;
}

/* Center the last row if there’s only one card in it */
.Venue > .Card:nth-child(4) {
  justify-self: center;
}

.Venue1 > .Card:nth-child(3) {
  justify-self: center;
}

.map {
  width: 1000px;
  height: 600px;
}

.hyderabad {
  margin: 70px 11%;
  padding: 30px;
  padding-bottom: 20px;
  text-align: left;
}

.about-hyd {
  display: flex;
  gap: 50px;
  font-family: "Montserrat";
}

.hyd-txt {
  flex-basis: 100%;
  border-bottom: 2px solid grey;
}

.hyd-pics {
  flex-basis: 100%;
}

.howToReach {
  font-size: 3rem;
  font-family: "Montserrat";
  font-weight: 600;
}

.howToReach1{
  font-size: 3rem;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  margin-top: 60px;
}

.title1 {
  font-family: "Montserrat";
  width: 75%;
}

.Card {
  margin: 10px 0 20px;
  border: solid grey;
  border-width: 2px;
  border-radius: 25px;
  padding: 25px;
  min-width: 300px;
}

.Card1 {
  margin: 10px 0 20px;
  border: solid grey;
  border-width: 2px;
  border-radius: 25px;
  padding: 25px;
}

.Card:hover {
  box-shadow: 0 0 10px grey;
}

.heading {
  font-weight: 550;
  font-size: 1.5rem;
  color: #000099;
}

blockquote {
  margin-top: 15px;
  font-weight: 405;
}

.card {
  text-align: left;
}

.card-body {
  padding: 10px;
}

.link {
  text-decoration: underline;
}

.link:hover {
  color: indigo;
}

.text-muted {
  color: black;
}

.list {
  margin-bottom: 25px;
  margin-left: 20px;
}
.list-title {
  font-size: 1.25rem;
}

.sublist {
  font-size: 1rem;
  margin-left: 25px;
  margin-top: 5px;
}

@media (max-width: 1000px) and (min-width: 700px) {
  .Venue {
    margin: 50px auto 75px;
    display: grid;
    grid: auto / auto auto;
  }

  .Venue1 {
    margin: 50px auto 75px;
    display: grid;
    grid: auto / auto auto;
  }

  .map {
    width: 90%;
    height: 412px;
  }
}

@media (max-width: 700px) {
  .Venue {
    margin: 20px auto 50px;
    display: grid;
    grid: auto / auto;
  }

  .Venue1 {
    margin: 20px auto 50px;
    display: grid;
    grid: auto / auto;
  }

  .hyderabad {
    margin: 70px auto;
    margin-top: 0;
  }

  .Card {
    margin: 30px;
    margin-top: 10px;
  }

  .map {
    margin: auto;
    width: 90%;
    height: 236px;
  }

  .about-hyd {
    display: flex;
    flex-direction: column;
  }
}
