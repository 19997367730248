a {
  color: inherit;
  text-decoration: inherit;
}

.contact {
  background-color: #111;
  color: white;
  padding: 20px 0;
}

.temp22 {
  display: none;
}

.email {
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 13px 0;
}

.gridc {
  display: flex;
  justify-content: space-around;
  text-align: left;
  margin-top: 20px;
}

.twti {
  position: relative;
  right: 0px;
}

@media (max-width: 800px) {
  .gridc {
    flex-direction: column;
    align-items: center;
  }

  .tempp {
    margin-top: 0;
  }

  .temp1 {
    margin: 3px 0;
    text-align: center;
    padding: 0;
    width: max-content;
  }

  .temp22 {
    display: initial;
  }
}
