.sponsors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-top: 180px;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 30px;
  margin-top: 50px;
  justify-content: space-around;
  justify-self: center;
  justify-items: center;
  align-items: center;
  align-self: center;
  gap: 20px;
  row-gap: 35px;
}

.logo_tier1 {
  width: min(80%, 700px);
  height: 100%;
}

.spr_logo{
  height: 200px;
  width: auto;
}

.logo_tier2 {
  width: min(60%, 500px);
  height: 100%;
}

.logo_tier3 {
  width: min(40%, 450px);
  height: 100%;
}

.spons_div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.element {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  /* width: 50%; */
  text-align: center;
  margin: 40px 0;
}

.element_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 50%;
  text-align: center;
  margin: 40px 0;
}

.element_3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 33%;
  text-align: center;
  margin: 40px 0;
}


.spons_2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 50px 0 0;
}

.spons_3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 50px 0;
}

.sp_4 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: 700;
}

.sp_4_2 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 600;
}

.sp_4_3 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 400;
}

.heading6 {
  font-family: "Montserrat";
  font-size: 1.18rem;
  margin: 8px 0;
  font-weight: 500;
}

@media (max-width: 1300px) {
  .element{
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .sp_4 {
    font-size: 2rem;
  }
  .sp_4_2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 500px) {
  .sp_4 {
    font-size: 1.2rem;
  }
  .sp_4_2 {
    font-size: 1rem;
  }
  .sp_4_3 {
    font-size: 0.8rem;
  }
}

@media (max-width: 1200px) {
  .element_3 {
    width: 50%;
  }

}

@media (max-width: 1000px) {
  .spons_2{
    flex-direction: column;
  }

  .spons_3{
    flex-direction: column;
  }

  .element_2 {
    width: 100%;
    margin: 30px 0;
  }

  .element_3 {
    width: 100%;
    margin: 30px 0;
  }

}