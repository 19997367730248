.App {
  text-align: center;
}

.content {
  transition: filter 0.8s;
}

.blurred {
  filter: blur(6px);
  pointer-events: none;
  user-select: none;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background-color: white;
  z-index: 1000;
  border: 1px solid #ccc;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.414);
}

.dialog_head {
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: 500;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
}

.register-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(33, 93, 166);
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.register-btn:hover {
  transform: scale(1.03);
  color: yellow;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
