.iithmap{
    padding-top: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.map{
    text-align: center;
    width: 70%;
    margin: 30px 0;
}

@media (max-width: 500px) {
    .map {
        width: 90%;
    }
}