.overview {
  display: flex;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

.overview-content {
  width: 50%;
}

.heading4 {
  text-align: left;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 620;
}

.overview-txt {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}

.para {
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.8rem;
  margin-right: 40px;
  color: rgb(0, 0, 0, 0.8);
}

.width80 {
  width: 90%;
}

.imgg {
  width: 40%;
}

.headd1 {
  text-align: left;
  border-bottom: 3px solid black;
  display: inline-block;
  width: fit-content;
}

.heading1 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 4rem;
  font-weight: 800;
}

@media (max-width: 1500px) {
  .overview {
    align-items: center;
    text-align: justify;
  }
  .overview-txt {
    width: 90%;
    padding-right: 40px;
  }

  .para {
    text-align: justify;
  }
  .width80 {
    width: 100%;
  }

  .heading1 {
    font-size: 2rem;
    text-align: center;
    width: 100%;
  }

  .overview-logo {
    display: flex;
    justify-content: center;
  }

  .headd1 {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .overview {
    flex-direction: column;
  }

  .overview-txt {
    padding: 0;
  }
}
