.detailed{
    padding-top: 170px;
}

.detail{
    font-family: "Montserrat";
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 50px 0 50px;
}