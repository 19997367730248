.tech {
  height: 1000px;
  color: black;
  padding: 150px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.tech_content {
  margin-top: 30px;
}

.tech_align {
  text-align: center !important;
  font-weight: 700;
}

.tech_align1 {
  text-align: center !important;
  font-weight: 700;
  margin-bottom: 30px;
}

.tech_chairs {
  text-align: center;
  margin: 5px 0;
  font-family: "Montserrat";
  font-size: 1.3rem;
  font-weight: 500;
}

.tech_flex {
  display: flex;
  gap: 30px;
  margin: 10px 0;
  text-align: center;
  justify-content: center;
}

.tech_univ {
  font-size: 0.95rem;
  font-weight: 500 !important;
}

.niist {
  font-size: 0.9rem;
}

@media (max-width: 700px) {
  .tech_flex {
    flex-direction: column;
    gap: 10px;
  }
  .tech_univ {
    font-size: 1rem;
  }
}
