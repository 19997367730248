.abstract {
  padding-top: 180px;
  text-align: center;
  margin-bottom: 100px;
}

.abstract_mb40 {
  margin-bottom: 40px;
  padding-top: 30px;
}

.abstract_docs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.g_bold {
  font-weight: 600;
}

.g_underline{
  text-decoration: underline;
}

.g_link{
  color: blue;
  text-decoration: underline;
}

.g_link:visited{
  color: purple;
}

.g_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.g_title {
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 700;
  margin: 50px 0 20px;
}

.g_italic {
  font-style: italic;
}

.guidelines {
  text-align: left;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0 10%;
}

.heading4a {
  text-align: left;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: 500;
}

.abstract_link {
  font-weight: 500;
  color: blue;
  text-decoration: underline;
}

.abstract_link:hover {
  color: purple;
}

.abstract_button {
  margin-top: 20px;
  padding: 20px 30px;
  width: fit-content !important;
  height: fit-content !important;
  border-radius: 20px;
  font-weight: 500;
  display: inline-block;
  background-color: rgb(33, 93, 166) !important;
}

.abs_{
  font-size: 1rem;
}

.strikeabs{
  text-decoration: line-through;
}

.abstact_fw {
  height: fit-content !important;
  width: fit-content !important;
}

@media (max-width: 550px) {
  .abstract {
    padding: 100px 20px 0;
  }

  .heading4a {
    font-size: 1.1rem;
  }

  .guidelines {
    font-size: 1.1rem;
  }

  .g_title {
    font-size: 1.3rem;
    margin: 30px 0 10px;
  }

  .abstract_button {
    width: 75% !important;
    font-size: 1rem;
    padding: 15px 10px;
  }
}
