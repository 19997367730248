.accomodation {
  padding-top: 180px;
}

.acc_para {
  font-family: "Montserrat";
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 20px 0 50px;
  padding: 0 40px;
}

.acc_para_head {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.g_list_1 {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
  margin: 0 auto;
}
