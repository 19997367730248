.events {
  margin: 40px 0 150px;
}

.heading1 {
  text-align: center;
  font-family: "Montserrat";
  font-size: 4rem;
  font-weight: 800;
}
