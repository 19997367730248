.workshops{
    padding-top: 180px;
}

.workshop-text{
    font-family: 'Montserrat';
    font-size: 1.4rem;
    padding: 0 30px;
}

.poster-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 0 0 40px;
}

@media (max-width: 900px) {
    .poster_img{
        width: 80%;
        height: auto;
    }
}

.poster_img{
    width: 80%;
    height: auto;
}