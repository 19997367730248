@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navwAnn {
  position: fixed;
  width: 100%;
}

.announcement {
  background-color: #335ab5;
  padding: 10px 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Montserrat";
  color: white;
  z-index: 1000;
  width: 100%;
}

.ann_link {
  color: white;
  text-decoration: underline;
  font-weight: 700;
}

.navbar {
  display: flex;
  padding: 0.8% 8%;
  align-items: center;
  box-shadow: 0 5px 5px 0 rgba(139, 134, 134, 0.2),
    0 6px 20px 0 rgba(180, 180, 180, 0.19);
  justify-content: space-between;
  background-color: white;
  width: 100%;
  z-index: 100;
}

.iithlogo {
  width: auto;
  height: 3.775rem;
}

.spacing{
  margin-left: 50px;
}

.spacing1{
  margin: 0 50vw ;
}

.vspacing{
  height: 5px;
}

.icon {
  width: 1.5rem;
  aspect-ratio: 1;
}

.icon-and-title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rheologo {
  height: 4rem;
}

.comp-nav-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  gap: 5px;
}

.comp-nav {
  font-size: 2.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.complogo {
  height: 3.5rem;
}

.marq{
  margin-left: 50px;
}

.navtxt {
  display: flex;
  gap: 2.2rem;
  align-items: center;
  transition: all 0.3s ease; /* Smooth transition for the hamburger menu */
  transform-origin: top;
}

.navtxt.expanded {
  transform: scaleY(1);
  height: auto; /* Adjust based on your content */
  overflow: visible; /* This line is changed to make popup menus visible */
}

.itm {
  color: #1e1e1e;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none; /* Removes underline from links */
  transition: color 0.3s ease; /* Optional: Smooth transition for hover effects */
}

.itm:hover {
  color: #0056b3; /* Optional: Change color on hover */
}

.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.popup-menu {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 100000;
}

.popup-item {
  display: block;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
}

.popup-item:hover {
  background-color: #ddd;
}

@media (max-width: 1000px) {
  .rheologo {
    display: none;
  }

  .iithlogo {
    display: none;
  }
  .hamburger {
    display: flex;
  }

  .navtxt {
    flex-direction: column;
    position: absolute;
    top: 4rem;
    z-index: 1000;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    transform: scaleY(0);
    overflow: visible;
  }

  .navtxt.expanded {
    display: flex;
    margin: 31px 0 0;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    gap: 12px;
  }
}

@media (max-width: 1750px) {
  .comp-nav {
    font-size: 2rem;
  }

  .itm {
    font-size: 1.4rem;
  }

  .rheologo {
    height: 3rem;
  }

  .iithlogo {
    height: 2.8rem;
  }

  .navbar {
    padding: 0.8% 2%;
  }

  .navtxt {
    gap: 1.5rem;
  }
}

@media (max-width: 1250px) {
  .comp-nav {
    font-size: 1.4rem;
  }
  .itm {
    font-size: 1.15rem;
  }
  .rheologo {
    height: 2.8rem;
  }

  .iithlogo {
    height: 2.5rem;
  }
}
