.video {
  margin: 20px 0;
  box-shadow: 5px 5px 20px black;
}

.videofull {
  margin: 20px 0;
}

@media (max-width: 1300px) {
  .video {
    aspect-ratio: 1.76744186;
    width: 90%;
    height: auto;
  }
}
